.sec-menu {
	background-color: #696969;
}

.sec-category {
	/* border: solid 1px blue; */
	display: flex;
	flex-direction: row;
}

.sec-category-list {
	/* border: solid 1px red; */
	flex: 1;
	min-height: 100vh;
}

.sec-sidebar-menu {
	/* border: solid 1px darkmagenta; */
	width: 90px;

	z-index: 4 !important;
}
.add-tags-padding {
	/* padding-top: 50px; */
}
.sticky-top {
	padding-top: 20px;
}

.sidebar-menu {
	padding-bottom: 40px;
	/* border: solid 1px blue; */
}

.sidebar-menu-collapse-box {
	/* background-color: #C0C0C0; */
	border: solid 1px transparent;
	/* border-bottom: solid 2px #C0C0C0; */
	margin-top: 6px;
}

.sidebar-menu-collapse {
	display: flex;
	flex-direction: column;
	margin-top: 6px;
}

.sidebar-menu ul {
	margin: 0;
	padding: 0;
}

.sidebar-menu ul li {
	list-style: none;
}

.sidebar-menu ul li:last-child {
	margin-bottom: 0px;
}

.sidebar-menu ul li a {
	text-decoration: none;
}

.sidebar-menu-link {
	/* border: solid 1px lime; */
	display: flex;
	flex-direction: column;
	align-items: center;
	text-decoration: none;
	margin-bottom: 6px;
	color: #101010;
	-webkit-transition: all 1s ease-out;
	-moz-transition: all 1s ease-out;
	-o-transition: all 1s ease-out;
	transition: all 1s ease-out;
}

.sidebar-menu-link:hover {
	color: blue;
}

.sidebar-menu-thumb-box {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	/* border-top-right-radius: 50%;
	border-bottom-right-radius: 50%; */
	padding:2px;
	margin-right: 2px;
	width: 45px;
	border-radius: 100%;
	height: 45px;
}

.sidebar-menu ul li a.active .sidebar-menu-thumb-box {
	background-color: var(--submenu-active-bgcolor);
	color: var(--submenu-active-text-color);
}
.sidebar-menutype.active {
	background-color: var(--navbg-color);
	color: var(--nav-active-text-color);
}

.sidebar-menu-thumb {
	overflow: hidden;
	height: 45px;
	border-radius: 50%;
	border: solid 1px transparent;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}

.sidebar-menu-icon {
	overflow: hidden;
	height: 45px;
	width: 45px;
	border-radius: 100%;
	background-color: lightpink;
	display: flex;
	align-items: center;
	justify-content: center;
}

.sidebar-menu-thumb img {
	height: 100%;
	width: 100%;
	object-fit: cover;
	/* contain-width & cover-height */
	object-position: center center;
}

.sidebar-menu-desc {
	/* border: solid 1px red; */
	margin-top: 2px;
	text-align: center;
	font-weight: 600;
	font-size: 12px;
	line-height: 14px;
	text-transform: capitalize;
}

.sidebar-menu-bars,
.sidebar-menu-back {
	border: solid 2px #101010;
	background-color: #101010;
	color: #fff;
	margin: 0 auto;
	height: 45px;
	width: 45px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 22px;
	-webkit-transition: all 1s ease-out;
	-moz-transition: all 1s ease-out;
	-o-transition: all 1s ease-out;
	transition: all 1s ease-out;
}

.sidebar-menu-bars:hover,
.sidebar-menu-back:hover {
	border: solid 2px #101010;
	background-color: #fff;
	color: #101010;
}

.sidebar-menu-back {
	margin-bottom: 6px;
}

.card-title {
	font-size: 18px;
}

.card-text {
	font-size: 14px;
}
